import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { enResources } from './locales/en-US/index.ts';

export const defaultNS = 'common';
export const ns = Object.keys(enResources);
export const resources = {
  'en-US': enResources
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: 'en-US',
  fallbackLng: 'en-US',
  ns,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
