import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid } from '@walmart-web/livingdesign-components';

import styles from './styles.module.css';
import WaitlistLayout from '../Layout/index.tsx';
import RegistrationConfirmation from './RegistrationConfirmation/index.tsx';
import WaitlistNotQualifiedThanksMessage from './NotQualifiedThanksMessage/index.tsx';
import WaitlistRegisterLaterMessage from './RegisterLaterMessage/index.tsx';
import { WAITLIST_SUBSCRIPTION_STATUS } from '../../../constants/common.js';

const BodyContent = () => {
  const location = useLocation();
  const [willRegisterLater, setWillRegisterLater] = useState(false);

  const params = new URLSearchParams(location.search);
  const waitlistSubscriptionStatus = params.get('status');

  const handleRegisterLaterClick = () => {
    setWillRegisterLater(true);
  };

  if (willRegisterLater) return <WaitlistRegisterLaterMessage />;

  if (waitlistSubscriptionStatus === WAITLIST_SUBSCRIPTION_STATUS.notQualified)
    return <WaitlistNotQualifiedThanksMessage />;

  if (waitlistSubscriptionStatus === WAITLIST_SUBSCRIPTION_STATUS.qualified)
    return (
      <RegistrationConfirmation
        handleRegisterLaterClick={handleRegisterLaterClick}
      />
    );

  return <></>;
};

const ValidateUserAndRecruit = () => {
  return (
    <WaitlistLayout>
      <Container UNSAFE_className={styles.container}>
        <Grid UNSAFE_className={styles.confirmationContent}>
          <BodyContent />
        </Grid>
      </Container>
    </WaitlistLayout>
  );
};

const ValidateUserAndRecruitWrapper: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const waitlistSubscriptionStatus = params.get('status');

  if (
    waitlistSubscriptionStatus &&
    [
      WAITLIST_SUBSCRIPTION_STATUS.qualified,
      WAITLIST_SUBSCRIPTION_STATUS.notQualified
    ].includes(waitlistSubscriptionStatus)
  )
    return <ValidateUserAndRecruit />;

  return children;
};

export default ValidateUserAndRecruitWrapper;
