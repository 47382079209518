import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const WaitlistHeader = () => {
  const { t } = useTranslation('common');

  return (
    <header>
      <nav className={styles.nav}>
        <div className={styles.landingWrapper}>
          <div className={styles.logo}>
            <img src={'assets/images/white-logo.png'} alt={'Spark'} />
            <p>{t('customerSpark')}</p>
          </div>
          <Link to="login" className={styles.login}>
            {t('login')}
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default WaitlistHeader;
