import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Body,
  Button,
  Grid,
  Heading
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const WaitlistRegisterLaterMessage = () => {
  const { t } = useTranslation('common');
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  return (
    <>
      <img
        className={styles.mailLogo}
        src="/assets/images/mail-logo.svg"
        alt="Mail logo"
        data-testid="mail-logo"
      />
      <Grid UNSAFE_className={styles.messageBox}>
        <Heading size="medium" data-testid="qualified-register-later-thanks">
          {t('thankYou')}
        </Heading>
        <Body
          size="medium"
          UNSAFE_className={styles.messageBody}
          data-testid="qualified-register-later-message"
        >
          {t('lookOutForRegistrationEmail', { ns: 'waitlist' })}
        </Body>
        <Grid UNSAFE_className={styles.buttonContainer}>
          <Button
            variant="primary"
            size="medium"
            onClick={handleClick}
            data-testid="not-qualified-ok-btn"
          >
            {t('ok')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default WaitlistRegisterLaterMessage;
