import React from 'react';

import styles from './styles.module.css';
import WaitlistHeader from '../Header/index.tsx';
import HomeFooter from '../../../components/Footer/HomeFooter';

const WaitlistLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <WaitlistHeader />
      <div className={styles.content}>{children}</div>
      <HomeFooter />
    </>
  );
};

export default WaitlistLayout;
