import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import App from './App';
import English from './languages/en-US.json';
import Spanish from './languages/es-MX.json';
import getCurrentEnv from './utils/env';
import { ENGLISH_LANGUAGES } from './constants/common';
import { IntlProvider } from 'react-intl';
import '@walmart-web/livingdesign-components/index.esm.css';
import { LivingDesignProvider } from '@walmart-web/livingdesign-components';
import './utils/i18n/index.ts';

let local = navigator.language,
  lang;

getCurrentEnv() === 'dev'
  ? ENGLISH_LANGUAGES.includes(local)
    ? (lang = English)
    : (lang = Spanish)
  : (lang = English);

const Main = () => (
  <LivingDesignProvider>
    <IntlProvider locale={local} messages={lang}>
      <App />
    </IntlProvider>
  </LivingDesignProvider>
);

const root = document.querySelector('#root');
ReactDOM.render(<Main />, root);
serviceWorker.unregister();
