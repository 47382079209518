import React from 'react';
import HomeFooter from '../../components/Footer/HomeFooter';
import { Trans, useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import WaitlistHeader from './Header/index.tsx';
import WaitlistFeaturedSection from '../WaitlistFeaturedSection/index.tsx';
import WaitlistValidateUserAndRecruit from './ValidateUserAndRecruit/index.tsx';

const PageContent = () => {
  const { t } = useTranslation('waitlist');

  return (
    <>
      <WaitlistHeader />
      <div className={styles.mainContainer}>
        <WaitlistFeaturedSection useWaitlistSiteHeading />
        <div className={styles.paddingLeft10}>
          <h2>
            {t('whoAreWe')} {t('howItWorks')}
          </h2>
        </div>
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/whoarewe.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>{t('whoAreWe')}</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  {t('whoAreWeAnswer')}
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/howdoeswork.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>{t('howItWorks')}</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  {t('howItWorksAnswer')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.marginTop30} ${styles.paddingLeft10}`}>
          <h2>{t('whatInItForMe')}</h2>
        </div>
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/reward.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>{t('earnRewards')}</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  {t('feedbackToEarnPoints')}
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/impact.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>{t('driveImpact')}</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  <Trans t={t} i18nKey="feedbackForProductImprovement">
                    Members voice their opinions about their favorite brands at
                    Walmart. As a member, your feedback could be used to make
                    decisions about product improvements.{' '}
                    <span className={styles.fwb}>Your voice matters!</span>
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

const Waitlist = () => {
  return (
    <WaitlistValidateUserAndRecruit>
      <PageContent />
    </WaitlistValidateUserAndRecruit>
  );
};

export default Waitlist;
